import React, { useEffect, useState } from 'react'
import withDocumentTitle from 'react-router-document-title';
import useDocumentTitle from '../../useDocumentTitle';
import './Countries.css'
import Country from './Country';

const Countries=() => {
  const [countries, setCountries]=useState([]);
  const [countryName, setCountryName]=useState('');

  const [satisfiedSearchs, setSatisfiedSearches]=useState(0);
  const [totalCountries, setTotalCountries]=useState([]);

  useEffect(() => {
    fetchCountriesData();
  }, []);

  const fetchCountriesData = async () => {
    const url = 'https://restcountries.com/v2/all';
    const response = await fetch(url)
    const data = await response.json();
    setCountries(data);
    setTotalCountries(data);
  }

  const searchCountryInfo = async (e)=>{
    e.preventDefault();
    const name = e.target.value;
    setCountryName(name);
    if(name){
      const url = `https://restcountries.com/v2/name/${name}`;
      const response = await fetch(url);
      if(response){
        const searchCountryData = await response.json();
        setSatisfiedSearches(searchCountryData);
        setCountries(searchCountryData);
      }

      else{
        setCountries([])
      }
    }
  }

  return (
    <div>
      <h1 className='countriesapi_title'>World Countries Data</h1>  
        <div className='countries_total'>
          
        <p>Currently we have {totalCountries.length} countries</p>
        {
          satisfiedSearchs.length>0 && countryName.length>0 ?
          (
          <p style={{color:'darkgreen'}}>
            {satisfiedSearchs.length} satisfied the search criteria
          </p>
          ) : ''
        }
    </div>
        
       <form className='form_countryInfo'>
          <input
            className='textCountryName'
            type='text'
            id='countryName'
            name='countryName'
            placeholder='Search by Country Name'
            value={countryName}
            onChange={searchCountryInfo}
          />
      </form>
        <div className='countries_info'>
          {
          countries.length<=0 &&(<div className='loader'><p>Loading...</p></div>)
          }

          {   
              countries.length>0  ? (
                countries.map((country)=>(
                  <Country 
                  key={country.name} 
                  country={country} />
                ))
              ) 

              :

              (
                countryName && <h2 className='error_msg'>Sorry! Search result not found</h2>
              )
          }
        </div>
    </div>
  )
}

export default withDocumentTitle("30Days | Countries API ") (Countries)