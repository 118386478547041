import React from 'react'
import '../Menus/Menu.css'
import {Link} from 'react-router-dom'

function Menu({children, to}) {
  return (
    <div>
     <ul className='menu_ul'>
         <li><Link to={'/'}>HOME </Link></li>
         <li><Link to={'/excercises'}>EXERCISES </Link></li>
         <li><Link to={'/cats-paradise'}>CAT'S PARADISE</Link></li>
         <li><Link to={'/countries-api'}>COUNTRIES API</Link></li>
         <li>
         <a 
            href="https://github.com/wobetu14"
            target="_blank"
            rel='noreferrer'
            >
              GitHub
            </a>
         </li>
     </ul> 
    </div>
  )
}

export default Menu