import React, { useState } from 'react'
import './Country.css'
import NumberFormat from 'react-number-format';

function Country({country:{name, capital, languages, population, currencies, altSpellings, flags}}) {
    
    const handleStateNames=()=>{
       if(altSpellings){
        let x=Array.from(altSpellings);
        return x[x.length-1];
       }
    }

  return (
    <div>
            <div className='country_container'>
                <div className='card_header'>
                    <div className='flag'>
                        <img src={flags ? flags.svg: 'null'} alt="" />
                    </div>
                </div>
                <div className='card_body'>
                       <h2 className='country_name'>{name}</h2>
                        <p><strong>Capital: </strong>{capital} </p>
                        
                        <p><strong>Languages: </strong>{languages[0] ? languages[0].name:''}, 
                        {languages[1] ? languages[1].name:''}, 
                        {languages[2] ? languages[2].name:''}</p>

                        <p><strong>Population: </strong>

                        <NumberFormat className="population"
                            thousandsGroupStyle="thousand"
                            value={population}
                            decimalSeparator="."
                            displayType="text"
                            thousandSeparator={true}
                            allowNegative={true} />
                        </p>

                        <p>
                            <span><h3>Currencies: </h3></span>
                            <span>Currency Code: <strong>{currencies ? currencies[0].code:''}</strong></span> <br /> 
                            <span>Currency Name: <strong>{currencies ? currencies[0].name:''}</strong></span> <br />
                            <span>Currency Symbol: <strong>{currencies ? currencies[0].symbol:''}</strong></span> <br />
                        </p>

                        <p>
                        <span>State Nomenclature: 
                            <strong>
                                {
                                <span>{handleStateNames()}</span>
                                }
                            </strong>
                        </span>
                        </p>
                </div>
            </div>
    </div>
  )
}

export default Country